<template>
	<div>
		<v-menu offset-y open-on-hover tile transition="slide-y-transition">
			<template v-slot:activator="{ on }">
				<v-list-item
					v-on="on"
					style="flex: 1 0 12vw; padding: 0px 1vw 0px 1vw; "
				>
					<span class="d-none d-sm-block"> <a>Account</a></span>
					<v-avatar size="40" contain alt="Account"
						><img
							src="@/assets/icons/everyone_my account.svg"
							alt="My Account"
					/></v-avatar>
				</v-list-item>
			</template>
			<v-list>
				<v-list-item :to="{ name: 'AmendAccountDetails' }">
					<v-list-item-avatar>
						<img src="@/assets/icons/everyone_my account.svg" />
					</v-list-item-avatar>
					<v-list-item-content>
						My Details
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					v-if="role === 'Interested Party'"
					:to="{ name: 'AmendLocationSizePreferences' }"
				>
					<v-list-item-avatar>
						<img src="@/assets/icons/everyone_my preferences.svg" />
					</v-list-item-avatar>
					<v-list-item-content>
						My Preferences
					</v-list-item-content>
				</v-list-item>

				<v-list-item @click="logout">
					<v-list-item-avatar>
						<img src="@/assets/icons/misc_delete.svg" />
					</v-list-item-avatar>
					<v-list-item-content>
						<a>Logout</a>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "myAccountMenu",
	data() {
		return {};
	},
	computed: {
		...mapGetters(["role"])
	},
	methods: {
		logout() {
			this.$store.dispatch("logOut").then(response => {
				this.$router.push({ name: "ConditionalHome" });
				response;
			});
		}
	}
};
</script>